const latestNewsSlider = new Swiper('.latest-news-swiper', {
    effect: 'slide',
    loop: false,
    slidesPerView: 3,
    spaceBetween: 20,
    grabCursor: true,
    navigation: {
      nextEl: '.news-next',
      prevEl: '.news-prev',
    },
    breakpoints: {
        300: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            loop: true,
        },
        600: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            loop: true,
        },
        767: {
            slidesPerView: 2,
            slidesPerGroup: 1,
            enabled: true,
            loop: true,
        },
        920: {
            slidesPerView: 3,
            slidesPerGroup: 1,
            enabled: false,
        }
    },
});